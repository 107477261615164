import { Helmet } from 'react-helmet';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ContactUs from '../components/ContactUs';
import AboutUs from '../components/AboutUs';
import Services from '../components/Services';

function Home() {
  return (
    <main>
      <Helmet>
        <title>Delmiro Mendez e Hijo S.A.</title>
      </Helmet>
      <Header />
      <AboutUs />
      <div className="background">
        <Services />
        <ContactUs />
      </div>
      <Footer />
    </main>
  );
}

export default Home;
