import '../assets/scss/footer.scss';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import {
  AiFillFacebook,
  AiFillInstagram,
  AiOutlineCopyright,
  AiOutlineMail,
  AiOutlinePhone,
  AiOutlineTwitter,
} from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import IcefatLogo from '../assets/images/icefat-logo.png';
import ArtimLogo from '../assets/images/artim-logo.png';
import ArcsLogo from '../assets/images/arcs-logo.png';
import AamLogo from '../assets/images/aam-logo.png';

function Footer() {
  const { t } = useTranslation();
  return (
    <footer>
      {/* Logos */}
      <div className="footer-container logos-container">
        <div className="logos">
          <div className="logo">
            <a href="https://icefat.org/" target="_blank" rel="noreferrer">
              <img src={IcefatLogo} alt="Icefat" />
            </a>
          </div>
          <div className="logo">
            <a href="https://artim.org/" target="_blank" rel="noreferrer">
              <img src={ArtimLogo} alt="Artim" />
            </a>
          </div>
        </div>
        <div className="logos">
          <div className="logo">
            <a href="https://www.arcsinfo.org/" target="_blank" rel="noreferrer">
              <img src={ArcsLogo} alt="Arcs" />
            </a>
          </div>
          <div className="logo">
            <a href="https://www.aam-us.org/" target="_blank" rel="noreferrer">
              <img src={AamLogo} alt="AAM" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-container information">
        <div className="information-item">
          <div className="information-icon">
            <HiOutlineLocationMarker size={14} />
          </div>
          <span> Dr. Mariano Moreno 4361, Munro, Pcia. de Buenos Aires, Argentina </span>
        </div>
        <div className="information-item">
          <div className="footer-info-icon">
            <AiOutlinePhone size={14} />
          </div>
          <span>
            {t('contact-us.phoneNumber')}
          </span>
        </div>
        <div className="information-item">
          <div className="information-icon">
            <AiOutlineMail size={14} />
          </div>
          <span> info@delmiromendez.com.ar</span>
        </div>
        <div className="information-item">
          <div className="information-icon">
            <AiOutlineCopyright size={14} />
          </div>
          <span>
            Copyright &copy;
            {new Date().getFullYear()}
          </span>
        </div>
      </div>
      <div className="footer-container socials-rights">
        <div className="socials">
          <div className="footer-instagram">
            <a href="https://www.instagram.com/delmiromendezehijo/" target="_blank" rel="noreferrer">
              <AiFillInstagram size="3rem" />
            </a>
          </div>
          <div className="footer-twitter">
            <a href="https://twitter.com/delmiro_mendez" target="_blank" rel="noreferrer">
              <AiOutlineTwitter size="3rem" />
            </a>
          </div>
          <div className="footer-facebook">
            <a href="https://www.facebook.com/delmiromendezehijo" target="_blank" rel="noreferrer">
              <AiFillFacebook size="3rem" />
            </a>
          </div>
        </div>
        <div className="rights">
          <div>
            <a href="/">
              {t('footer.privacy-policy')}
            </a>
          </div>
          <div>
            <a href="/">{t('footer.terms')}</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
