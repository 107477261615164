import { Helmet } from 'react-helmet';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Images from '../components/Images';

function Gallery() {
  return (
    <main>
      <Helmet>
        <title>Delmiro Mendez e Hijo S.A. -  Gallería</title>
      </Helmet>
      <Header />
      <Images />
      <Footer />
    </main>
  );
}

export default Gallery;
