import { useTranslation } from 'react-i18next';
import Customs from '../assets/images/services/custom.jpg';
import Storage from '../assets/images/services/storage.jpg';
import Packing from '../assets/images/services/packing.jpg';
import Installation from '../assets/images/services/mounting.jpg';
import Relocations from '../assets/images/services/movings.jpg';
import Transports from '../assets/images/services/transport.jpg';

import '../assets/scss/services.scss';

function Services() {
  const { t } = useTranslation();
  return (
    <section id="services">
      <div className="container">
        <div className="services-content">
          <div className="services-header">
            <h2>{t('services.title')}</h2>
          </div>
          <div className="services-items-2">
            <div className="service-item-2">
              <div className="service-image service-image-right">
                <img src={Storage} alt="" className="responsive" />
              </div>
              <div className="service-info service-overlap service-overlap-right">
                <div className="service-item-header">
                  <h3>{t('services.private-storage-for-fine-art.title')}</h3>
                </div>
                <div className="service-item-content">
                  <p>
                    {t('services.private-storage-for-fine-art.p1')}
                  </p>
                  <ul>
                    <li>
                      {t('services.private-storage-for-fine-art.p2')}
                    </li>
                    <li>
                      {t('services.private-storage-for-fine-art.p3')}
                    </li>
                    <li>
                      {t('services.private-storage-for-fine-art.p4')}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="service-item-2">
              <div className="service-image">
                <img src={Packing} alt="" className="responsive" />
              </div>
              <div className="service-info service-overlap">
                <div className="service-item-header">
                  <h3>{t('services.packing.title')}</h3>
                </div>
                <div className="service-item-content">
                  <p>
                    {t('services.packing.p1')}
                  </p>
                  <p>
                    {t('services.packing.p2')}
                  </p>
                  <p>
                    {t('services.packing.p3')}
                  </p>
                  <p>
                    {t('services.packing.p4')}
                  </p>
                </div>
              </div>
            </div>
            <div className="service-item-2">
              <div className="service-image service-image-right">
                <img src={Transports} alt="" className="responsive" />
              </div>
              <div className="service-info service-overlap service-overlap-right">
                <div className="service-item-header">
                  <h3>{t('services.national-and-international-transports.title')}</h3>
                </div>
                <div className="service-item-content">
                  <p>
                    {t('services.national-and-international-transports.p1')}
                  </p>
                  <p>
                    {t('services.national-and-international-transports.p2')}
                  </p>
                  <p>
                    {t('services.national-and-international-transports.p3')}
                  </p>
                </div>
              </div>
            </div>
            <div className="service-item-2">
              <div className="service-image">
                <img src={Customs} alt="" className="responsive" />
              </div>
              <div className="service-info service-overlap">
                <div className="service-item-header">
                  <h3>{t('services.custom-formalities-couriers-and-insurance.title')}</h3>
                </div>
                <div className="service-item-content">
                  <p>
                    {t('services.custom-formalities-couriers-and-insurance.p1')}
                  </p>
                  <p>
                    {t('services.custom-formalities-couriers-and-insurance.p2')}
                  </p>
                  <p>{t('services.custom-formalities-couriers-and-insurance.p3')}</p>
                  <ul>
                    <li>
                      {t('services.custom-formalities-couriers-and-insurance.p4')}
                    </li>
                    <li>
                      {t('services.custom-formalities-couriers-and-insurance.p5')}
                    </li>
                    <li>
                      {t('services.custom-formalities-couriers-and-insurance.p6')}
                    </li>
                    <li>
                      {t('services.custom-formalities-couriers-and-insurance.p7')}
                    </li>
                    <li>
                      {t('services.custom-formalities-couriers-and-insurance.p8')}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="service-item-2">
              <div className="service-image service-image-right">
                <img src={Installation} alt="" className="responsive" />
              </div>
              <div className="service-info service-overlap service-overlap-right">
                <div className="service-item-header">
                  <h3>
                    {t('services.installation.title')}
                  </h3>
                </div>
                <div className="service-item-content">
                  <p>
                    {t('services.installation.p1')}
                  </p>
                  <p>
                    {t('services.installation.p2')}
                  </p>
                </div>
              </div>
            </div>
            <div className="service-item-2">
              <div className="service-image service-image">
                <img src={Relocations} alt="" className="responsive" />
              </div>
              <div className="service-info service-overlap service-overlap">
                <div className="service-item-header">
                  <h3>
                    {t('services.national-and-international-relocations.title')}
                  </h3>
                </div>
                <div className="service-item-content">
                  <p>
                    {t('services.national-and-international-relocations.p1')}
                  </p>
                  <p>
                    {t('services.national-and-international-relocations.p2')}
                  </p>
                  <p>
                    {t('services.national-and-international-relocations.p3')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
