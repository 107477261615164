import '../assets/scss/gallery.scss';
import { useEffect, useState } from 'react';
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import Img1 from '../assets/images/gallery/liberty.jpg';
import Img1Thumbnail from '../assets/images/gallery/liberty-thumbnail.jpg';
import Img2 from '../assets/images/gallery/team.jpg';
import Img2Thumbnail from '../assets/images/gallery/team-thumbnail.jpg';
import Img3 from '../assets/images/gallery/label.jpg';
import Img3Thumbnail from '../assets/images/gallery/label-thumbnail.jpg';
import Img4 from '../assets/images/gallery/truck-00.jpg';
import Img4Thumbnail from '../assets/images/gallery/truck-00-thumbnail.jpg';
import Img5 from '../assets/images/gallery/mar-del-plata.jpg';
import Img5Thumbnail from '../assets/images/gallery/mar-del-plata-thumbnail.jpg';
import Img6 from '../assets/images/gallery/truck-04.jpg';
import Img6Thumbnail from '../assets/images/gallery/truck-04-thumbnail.jpg';
import Img7 from '../assets/images/gallery/museum.jpg';
import Img7Thumbnail from '../assets/images/gallery/museum-thumbnail.jpg';
import Img8 from '../assets/images/gallery/truck-01.jpg';
import Img8Thumbnail from '../assets/images/gallery/truck-01-thumbnail.jpg';
import Img9 from '../assets/images/gallery/truck-02.jpg';
import Img9Thumbnail from '../assets/images/gallery/truck-02-thumbnail.jpg';
import Img10 from '../assets/images/gallery/proa.jpg';
import Img10Thumbnail from '../assets/images/gallery/proa-thumbnail.jpg';
import Img11 from '../assets/images/gallery/cases.jpg';
import Img11Thumbnail from '../assets/images/gallery/cases-thumbnail.jpg';
import Img12 from '../assets/images/gallery/cases-01.jpg';
import Img12Thumbnail from '../assets/images/gallery/cases-01-thumbnail.jpg';
import Img13 from '../assets/images/gallery/case.jpg';
import Img13Thumbnail from '../assets/images/gallery/case-thumbnail.jpg';
import Img14 from '../assets/images/gallery/truck-03.jpg';
import Img14Thumbnail from '../assets/images/gallery/truck-03-thumbnail.jpg';
import Img15 from '../assets/images/gallery/cases-02.jpg';
import Img15Thumbnail from '../assets/images/gallery/cases-02-thumbnail.jpg';

function Images() {
  const images = [{
    source: Img1,
    thumbnail: Img1Thumbnail,
    data: 'Liberty',
  }, {
    source: Img2,
    thumbnail: Img2Thumbnail,
    data: 'Team',
  }, {
    source: Img3,
    thumbnail: Img3Thumbnail,
    data: 'Old label',
  }, {
    source: Img4,
    thumbnail: Img4Thumbnail,
    data: 'Truck in exposition',
  }, {
    source: Img5,
    thumbnail: Img5Thumbnail,
    data: 'Working in Mar del Plata',
  }, {
    source: Img6,
    thumbnail: Img6Thumbnail,
    data: 'Truck at the office',
  }, {
    source: Img7,
    thumbnail: Img7Thumbnail,
    data: 'Truck in a museum',
  }, {
    source: Img8,
    thumbnail: Img8Thumbnail,
    data: 'Truck',
  }, {
    source: Img9,
    thumbnail: Img9Thumbnail,
    data: 'Windmill',
  }, {
    source: Img10,
    thumbnail: Img10Thumbnail,
    data: 'Working in Proa',
  }, {
    source: Img11,
    thumbnail: Img11Thumbnail,
    data: 'Cases',
  }, {
    source: Img12,
    thumbnail: Img12Thumbnail,
    data: 'Cases 2',
  }, {
    source: Img13,
    thumbnail: Img13Thumbnail,
    data: 'Case with labels',
  }, {
    source: Img14,
    thumbnail: Img14Thumbnail,
    data: 'Truck at the office 2',
  }, {
    source: Img15,
    thumbnail: Img15Thumbnail,
    data: 'Order cases',
  },
  ];

  const { t } = useTranslation();

  const [imageToShow, setImageToShow] = useState({});
  const [showLightbox, setShowlightbox] = useState(false);
  const showNext = (e) => {
    e.stopPropagation();
    const currentIndex = images.findIndex((i) => i.data === imageToShow.data);
    const nextImage = images[currentIndex + 1];
    setImageToShow(nextImage || images[0]);
  };

  const showPrev = (e) => {
    e.stopPropagation();
    const currentIndex = images.findIndex((i) => i.data === imageToShow.data);
    const nextImage = images[currentIndex - 1];
    setImageToShow(nextImage || images[images.length - 1]);
  };

  const handleKeyDown = (event) => {
    event.stopPropagation();
    if (event.keyCode === 39) {
      showNext(event);
    }
    if (event.keyCode === 37) {
      showPrev(event);
    }
    if (event.keyCode === 27) {
      setShowlightbox(false);
    }
  };

  const showImage = (image) => {
    setImageToShow(image);
    setShowlightbox(true);
  };
  // hide lightbox
  const hideLightBox = () => {
    setShowlightbox(false);
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    showLightbox ? document.body.classList.add('of') : document.body.classList.remove('of');
  }, [showLightbox]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    // cleanup this component
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [imageToShow]);

  return (
    <section id="gallery">
      <div className="container">
        <div className="gallery-header">
          <h1>
            {t('gallery.title')}
          </h1>
        </div>
        <div className="gallery">
          {
            images.map((image) => (
              <div className="gallery-item" key={image.data}>
                <button
                  type="button"
                  data-lightbox="example-set"
                  className="lightbox"
                  onClick={() => showImage(image)}
                  data-title={image.data}
                >
                  <img src={image.thumbnail} alt={image.data} className="hover-shadow" />
                </button>
              </div>
            ))
          }
        </div>
      </div>
      {
        showLightbox
          ? (
            <div>
              <button type="button" onClick={hideLightBox}>
                <div id="lightbox">
                  <div className="control">
                    <button type="button" className="control-button" onClick={showPrev}>
                      <IoMdArrowRoundBack aria-label="Show previous" size={40} />
                    </button>
                  </div>
                  <img id="lightbox-img" src={imageToShow.source} alt={imageToShow.data} />
                  <div className="control">
                    <button type="button" className="control-button" onClick={showNext}>
                      <IoMdArrowRoundForward aria-label="Show next" size={40} />
                    </button>
                  </div>
                </div>
              </button>
            </div>
          )
          : null
      }
    </section>
  );
}

export default Images;
