import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function LangSelector({ click }) {
  const { i18n, t } = useTranslation();
  const [, setSelectedLang] = useState('en');

  const changeLanguage = async (language) => {
    setSelectedLang(language);
    await i18n.changeLanguage(language);
  };

  return (
    <div className="language-options">
      <ul className={click ? 'nav-options icons-options' : 'nav-options'}>
        <li className="nav-option">
          <button
            type="button"
            onClick={async () => changeLanguage('en')}
            className={`${i18n.language === 'en' ? 'selected' : null}`}
          >
            {t('en')}
          </button>
        </li>
        <li className="nav-option">
          <button
            type="button"
            onClick={() => changeLanguage('es')}
            className={`${i18n.language === 'es' ? 'selected' : ''}`}
          >
            {' '}
            {t('spa')}
            {' '}
          </button>
        </li>
      </ul>
    </div>
  );
}

export default LangSelector;
