import '../assets/scss/header.scss';
import Navbar from './Navbar';

function Header() {
  return (
    <header>
      <Navbar />
    </header>
  );
}

export default Header;
