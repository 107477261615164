import { Helmet } from 'react-helmet';
import { MdConstruction } from 'react-icons/md';
import Footer from '../components/Footer';
import Header from '../components/Header';
import '../assets/scss/notFound.scss';

function UnderConstruction() {
  return (
    <main>
      <Helmet>
        <title> Delmiro Mendez e Hijo S.A. | 404 - no encontrado </title>
      </Helmet>
      <Header />
      <section className="error">
        <div className="flex-wrapper">
          <div className="error-content row justify-content-center align-items-center">
            <div className="under-construction_content">
              <div className="error-content_404">
                <h2>
                  Proximamente!

                </h2>
                <MdConstruction size="3rem" />
              </div>
              <div className="error-content_message">
                <h2 className="font-bold">Estamos preparando nuestra historia</h2>
                <a href="/" className="form-button">
                  <button type="button" className="btn btn-primary">
                    Volver al Inicio
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
}

export default UnderConstruction;
