import '../assets/scss/contactUs.scss';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

function ContactUs() {
  const { t } = useTranslation();
  return (
    <section id="contact">
      <div className="container-fluid contact-us">
        <div className="contact-us-header">
          <h1>{t('contact-us.subtitle')}</h1>
        </div>
        <div className="row">
          <div className="row-item">
            <div className="row-item-icon">
              <HiOutlineLocationMarker size={48} />
            </div>
            <div className="row-item-heading">
              <h3>{t('contact-us.address')}</h3>
            </div>
            <div className="row-item-content">
              <h5>
                <span> Dr. Mariano Moreno 4361, Munro, Pcia. de Buenos Aires, Argentina </span>
              </h5>
            </div>
          </div>
          <div className="row-item">
            <div className="row-item-icon">
              <AiOutlinePhone size={48} />
            </div>
            <div className="row-item-heading">
              <h3>{t('contact-us.phone')}</h3>
            </div>
            <div className="row-item-content">
              <h5>
                <span>
                  {t('contact-us.phoneNumber')}
                </span>
              </h5>
            </div>
          </div>
          <div className="row-item">
            <div className="row-item-icon">
              <AiOutlineMail size={48} />
            </div>
            <div className="row-item-heading">
              <h3>{t('contact-us.email')}</h3>
            </div>
            <div className="row-item-content">
              <h5><span> info@delmiromendez.com.ar</span></h5>
            </div>
          </div>
        </div>
      </div>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3287.6081139103057!2d-58.53013248417717!3d-34.51281806020234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb0ec45938b21%3A0x82c8496bff9ea0d9!2sDr.%20Mariano%20Moreno%204361%2C%20B1605BOE%20Munro%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1654138115873!5m2!1ses-419!2sar"
          width="100%"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="map"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    </section>
  );
}

export default ContactUs;
