import '../assets/scss/aboutUs.scss';
import { useTranslation } from 'react-i18next';
import video from '../assets/images/home-about-us.mp4';
import videowebm from '../assets/images/home-about-us.webm';

function AboutUs() {
  const { t } = useTranslation();
  return (
    <section id="about-us">
      <div className="about-us">
        <div className="container-fluid about-us-container">
          <div className="row-about-us-2">
            <div className="right">
              <video autoPlay muted loop id="myVideo">
                <source src={video} type="video/mp4" />
                <source src={videowebm} type="video/webm" />
              </video>
            </div>
            <div className="left">
              <div className="row-about-us-content">
                <div className="row-content-header">
                  <h2>
                    {t('about-us.title')}
                  </h2>
                </div>
                <div className="row-content-text">
                  <p>
                    {t('about-us.p1')}
                  </p>
                  <p>
                    {t('about-us.p2')}
                  </p>
                  <p>
                    {t('about-us.p3')}
                  </p>
                </div>
                <div className="row-content-button">
                  <button type="button" className="btn btn-primary btn-about-us">
                    <a href="/about-us">
                      {t('about-us.know-more')}
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
