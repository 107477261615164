import '../assets/scss/header.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MenuIcon } from '../assets/images/menu.svg';
import Logo from '../assets/images/dmeh-logo.jpg';
import LangSelector from './LangSelector';

function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [show, setShow] = useState(false);
  const controlNavbar = () => {
    if (window.scrollY <= 100) {
      setShow(false);
    } else {
      setShow(true);
    }
  };
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener('scroll', controlNavbar);
    return () => {
      window.removeEventListener('scroll', controlNavbar);
    };
  }, []);

  return (
    <nav className={`navbar ${show && 'nav__shrink'}`}>
      <div className="logo-nav">
        <div className="logo-container">
          <div className="logo-navbar">
            <a href="/">
              <img src={Logo} alt="" />
            </a>
          </div>
        </div>
      </div>

      <div className={click ? 'menu active' : 'menu'}>
        <LangSelector click={click} />
        <div className="categories-options">
          <ul className={click ? 'nav-options active' : 'nav-options'}>
            <li className="nav-option">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="/#services">
                {t('services.title')}
              </a>
            </li>
            <li className="nav-option">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="/#contact">
                {t('contact-us.title')}
              </a>
            </li>
            <li className="nav-option">
              <a href="/gallery">
                {t('gallery.title')}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="mobile-menu">
        <button type="button" onClick={handleClick}>
          <MenuIcon className="flag-icon" />
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
